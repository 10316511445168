.blog-post .postHero-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-line-pack: start;
      align-content: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.blog-post .postHero-wrapper > * {
  min-width: 100%;
}
.blog-post .postHero-wrapper .btn-back {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding-bottom: 2rem;
}
.blog-post .postHero-wrapper .btn-back svg {
  margin-right: 1rem !important;
}
.blog-post .post .post__content p {
  padding-top: 0.05rem;
}
.blog-post .post .post__content blockquote {
  padding-top: 0.05rem;
  color: #f07b5d;
  font-size: 1.5rem;
  text-align: center;
}
.blog-post .post .post__content img {
  border-radius: 25px;
}
.blog-post .post .alignleft {
  float: left;
  margin-right: 1.5em;
}
.blog-post .post .alignright {
  float: right;
  margin-left: 1.5em;
}
.blog-post .post .post__content {
  max-width: 830px;
  margin: auto;
}
.blog-post .post .post__content * {
  font-family: "Open Sans", sans-serif !important;
}
.blog-post .post #postHero {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.blog-post .post #postHero .postHero-image {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  min-width: 100%;
  position: relative;
}
.blog-post .post #postHero .postHero-image:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50%;
  height: 12px;
}
.blog-post .post #postHero .postHero-content {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  min-width: 100%;
  margin: 2rem auto;
}
.blog-post .post #postHero .postHero-title {
  color: #292E44;
  font-size: 20px;
  font-weight: 900;
  margin: 0 auto;
}
.blog-post .post #postHero .postDate {
  display: block;
  text-align: right;
  max-width: 90%;
  margin: auto;
  margin-top: 2rem;
}
@media (min-width: 768px) {
.blog-post .post #postHero {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    margin-bottom: 60px;
    margin-top: 0;
}
.blog-post .post #postHero .postHero-image {
    -webkit-box-flex: 50%;
        -ms-flex: 50%;
            flex: 50%;
    min-width: 50%;
}
.blog-post .post #postHero .postHero-content {
    -webkit-box-flex: 50%;
        -ms-flex: 50%;
            flex: 50%;
    min-width: 50%;
}
.blog-post .post #postHero .postHero-content .container {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.blog-post .post #postHero .postHero-title {
    font-size: 26px;
    max-width: 400px;
}
}
@media (min-width: 1200px) {
.blog-post .post #postHero .postHero-title {
    font-size: 30px;
    max-width: 500px;
}
}
@media (min-width: 1600px) {
.blog-post .post #postHero {
    margin-bottom: 90px;
}
.blog-post .post #postHero .postHero-title {
    font-size: 40px;
    max-width: 700px;
}
}
.blog-post .post #postHero img {
  width: 100%;
}
.blog-post .post .post-separator {
  border-top: 1px solid #B3B3B3;
  opacity: 0.5;
  margin: 30px 0;
}
@media (min-width: 768px) {
.blog-post .post .post-separator {
    margin: 30px 30px 60px 30px;
}
}
.blog-post .consumer .postHero-title {
  color: #f07b5d !important;
}
.blog-post .consumer .postHero-image:after {
  background-color: #f07b5d;
}
.blog-post .consumer .post__content a,
.blog-post .consumer .post__content a:hover {
  color: #f07b5d;
}
.blog-post .business .postHero-title {
  color: #292E44 !important;
}
.blog-post .business .postHero-image:after {
  background-color: #292E44;
}
.blog-post .business .post__content a,
.blog-post .business .post__content a:hover {
  color: #292E44;
}