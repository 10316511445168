.btn-back {
  display: inline-block;
}
.btn-flex {
  color: #0077CA;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}
.btn-flex span {
  margin-left: 10px;
  position: relative;
}
.btn-flex span:after {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  left: 0;
  bottom: -5px;
  opacity: 0;
  -webkit-transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  background: #0077CA;
}
.btn-flex:hover, .btn-flex:hover * {
  text-decoration: none;
}
.btn-flex:hover span:after {
  opacity: 1;
  bottom: 0px;
}
.btn-flex svg {
  width: 16px;
}