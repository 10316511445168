.blog-topbar .page-subtitle {
  margin-bottom: 0;
}
.blog-topbar .topbar {
  padding: 30px 0;
}
.blog-topbar.consumer .topbar {
  background: #f07b5d;
}
.blog-topbar.consumer .page-title,
.blog-topbar.consumer .page-subtitle {
  color: #333333;
}
.blog-topbar.business .topbar {
  background: #292E44;
}
.blog-topbar.business .page-title,
.blog-topbar.business .page-subtitle {
  color: #fff;
}